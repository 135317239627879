const translateFreeformFields = (freeFormElement: HTMLElement) => {
  // Since we don't have the PRO version of Freeform we need to hack our way into translating field labels
  const translations: { [key: string]: string } = {
    Nachname: 'Last Name',
    Vorname: 'First Name',
    Telefon: 'Phone',
    'Persönliche Nachricht': 'Personal Message',
  }
  const labels = freeFormElement.querySelectorAll('label.freeform-label')
  labels.forEach((label: Element) => {
    const currentText = label.textContent?.trim() ?? ''
    if (translations[currentText]) {
      label.textContent = translations[currentText]
    }
  })

  // Finally we need to translate the submit button
  const submitButton = freeFormElement.querySelector('button[type="submit"]')
  if (submitButton) {
    setTimeout(() => {
      submitButton.textContent = window.FreeformSubmitButtonLabel
    }, 0)
  }
}

document.addEventListener('freeform-ready', (event: any) => {
  const freeFormObject = event.freeform
  const freeFormElement = freeFormObject.form

  // Reference: https://docs.solspace.com/craft/freeform/v5/developer/js-plugin/
  freeFormObject.options.successBannerMessage =
    window.FreeformSuccessBannerMessage
  freeFormObject.options.errorBannerMessage = window.FreeformErrorBannerMessage
  freeFormObject.options.processingText = window.FreeformSubmitProcessingMessage

  // Put the success banner below
  // Reference: https://docs.solspace.com/craft/freeform/v5/guides/templating/ajax-forms/#display-success-banner-at-bottom
  freeFormElement.addEventListener('freeform-render-success', (evt: Event) => {
    evt.preventDefault()

    const rows = freeFormElement.querySelectorAll('.freeform-row')
    const lastRow = rows.item(rows.length - 1)

    const successElement = document.createElement('div')
    successElement.classList.add(freeFormObject.options.successClassBanner)
    successElement.appendChild(
      document.createTextNode(freeFormObject.options.successBannerMessage),
    )

    lastRow.insertAdjacentElement('afterend', successElement)
  })

  if (window.CRAFT_LANGUAGE === 'en') {
    // Initially translate if english
    setTimeout(() => {
      translateFreeformFields(freeFormElement)
    }, 0)

    freeFormElement.addEventListener('freeform-ajax-after-submit', () => {
      // Translate after submission because Freeforms resets the labels
      setTimeout(() => {
        translateFreeformFields(freeFormElement)
      }, 0)
    })
  }
})
